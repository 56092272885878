import React from "react";

import Head from "../components/head";
import Au1 from "../components/pages/about/au1";
const About = () => (
  <>
    <Head dis="Set up as Gujarat's finest IELTS Training Institute with dedicated, Student Centric faculty and adopting 21st Century training techniques imparting both Online/Offline Training." />
    <Au1 />
  </>
);

export default About;
