import React from "react";

export default () => {
  return (
    <>
      <div className="au1 container">
        <h5>Our Products</h5>
        <p>
          <strong>OCSO</strong>™ is sports, fitness brand with the best highly
          fashionable clothes for men, women, . We know that quality is a key
          factor that will help us sell our brand which is why we have but in
          place a competent quality assurance team that will ensure that all our
          clothes meet and even surpass our customers’ expectations. <br /> We
          will work hard to ensure that <strong>OCSO</strong>™ Clothing Line is
          not just accepted nationally in the all of India, but also in other
          parts of the world. Here is a list of our products;
          <br />
          <br /> Men’s T-shirts for sports and fitness
          <br /> Men’s track pent <br />
          Women’s T-shirts for sports and fitness
          <br /> Women’s track Pants <br />
          Sports Jackets (male, female,)
          <br /> Sport gear
        </p>
        <h5>Our Mission</h5>
        <p>
          Our mission is to establish a premium sports and fitness clothing line
          that will make available a wide range of clothes for male and female,
          a clothing label that will favorably with international clothing
          labels like puma, Adidas, reboot , Hilfiger, Hugo Boss, Gap, HRX and
          Calvin Kline at the global stage.
        </p>
      </div>
    </>
  );
};
